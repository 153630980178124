<template>
    <div id="order_app">
      <div class="header_div">
          <div class="car_name_div">
              <div>{{car_info.car_name}}</div>
          </div>
          <div class="car_price_div">
              <font>{{car_info.total_price}}</font> &nbsp; <font style="font-size:20px;">元</font>
              <van-icon size="15" name="question-o" @click="question_icon_show=!question_icon_show" />
              <label v-show="question_icon_show" style="font-size:14px">预估报废价</label>
          </div>
      </div>
      <div class="body_div">
          <div class="message_info_div">
            友情提示
          </div>
          <div class="messge_ul">
              <ul>
                  <li v-for="(item,index) in info" :key="index" style="white-space: pre-line;">{{item}}</li>
                  <li>报废前请确认您的资料准备齐全：</li> 
              </ul>
          </div>
          <div class="user_message_div">
              <ul>
                  <li class="message_info_div">【必要材料】</li>
                  <li>车辆行驶证 原件</li>
                  <li>车辆登记证 原件</li>
                  <li>前后车牌</li>
              </ul>
              <div style="height:5px;"></div>
              <ul>
                  <li class="message_info_div">【个人车辆】</li>
                  <li>车主身份证明 复印件 2份</li>
              </ul>
              <ul>
                  <li class="message_info_div">【公司车辆】</li>
                  <li>营业执照复印件加盖公章 1份</li>
              </ul>
              <ul>
                  <li class="message_info_div">【事故车】</li>
                  <li style="padding-right:20px;">《事故责任认定书》原件，或加盖事故认定部门公章的复印件</li>
              </ul>
          </div>
      </div>
      <div class="fixed_submit_div">
          <div style="height:20px;"></div>
          <p style="width:20vw;text-align:center;margin-top:5px;"><van-icon  size="40" name="shopping-cart-o" /></p>
          <p class="price_div">合计：<font>￥{{car_info.total_price}}</font></p>
          <div class="submit_div">
              <van-button style="width:50%" size="large"  @click="changeStatus();"   round   type="default">{{isCustomer?'取消':'我再想想'}}</van-button>
              <van-button style="width:47%" size="large"  @click="onclick"  color="#697d74"  round   type="primary">{{isCustomer?'预 &nbsp; 约':'确 &nbsp; 认'}}</van-button>
          </div>
      </div>
    </div>
</template>
<script>
import wx from 'weixin-js-sdk';
import { Icon,Button} from 'vant';
import {collectionSetSubmit} from '../../api/provider'
import {loginUrlSotre} from '../../store/token'
    export default({
        name:'pricing',
        components:{
            [Button.name]:Button,
            [Icon.name]:Icon,
        },
        data() {
            return {
                info:[],
                question_icon_show:false,
                collectId:'',
                car_info:{
                    car_name:'报废车辆',
                    total_price:'',
                },
                sysUrl:'',
                id:'',
                isCustomer:false,
            }
        },
        methods:{
            onclick(){
                if(this.isCustomer){
                    this.id&&wx.closeWindow();
                    this.$router.replace(this.sysUrl)
                }else{
                    this.$router.go(-1);
                }
            },
           changeStatus(){
               if(this.isCustomer){
                   this.$toast.success("报废成功！");
                   wx.closeWindow();
                   return;
               }
               var body = {
                   collectId:this.collectId,
                   submitStatus:0
               }
               this.$toast.loading({duration: 0,forbidClick: true,message: '提交中',});
               collectionSetSubmit(body).then(res=>{
                   this.$toast.clear();
                   console.log(res);
                   if(this.isCustomer){
                        this.$router.push('/aichaichemhome');
                    }else{
                        this.$router.push(this.sysUrl);
                    }
               });
           }
        },
        mounted(){
            this.id = this.$route.query.providerId;
            var url = loginUrlSotre.get();
            if(url=='/personal'){
                this.sysUrl = "/personalIndex";
            }else{
                this.sysUrl = this.id?('/uploadOrder?providerId='+this.id):'/mindex';
                console.log(this.sysUrl);
            }
        },
        created(){
            this.car_info.car_name=this.$route.query.car_name||this.car_info.car_name;
            this.car_info.total_price=this.$route.query.total_price;
            this.collectId = this.$route.query.collectId;
            this.isCustomer = this.$route.query.isCustomer;
        }
    })
</script>
<style scoped>
@import "./orderPricing.css";
</style>