import axios from "./request"
export function getUserInfo(){
  return axios({
      url: "/provider/GetCurrentProviderInfo",
      method: "get",
      timeout:10000,
  })
}
export function getTags(){
  return axios({
      url: "/tag/GetPageList",
      method: "get",
      timeout:10000,
  })
}
export function InviteCode(body){
  return axios({
      url:"user/InviteCode",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function UseInviteCode(body){
  return axios({
      url:"provider/UseInviteCode",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      params: body
  });
}
export function MPQRTicket(body){
  return axios({
      url:"user/MPQRTicket",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function BusinessScope(body){
  return axios({
      url:"/provider/BusinessScope",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      params: body
  });
}
export function providerUpdateIdCard(body){
  return axios({
      url: "/provider/UpdateIdCard",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function providerUpdateMerchantName(body){
  return axios({
      url: "/provider/UpdateMerchantName",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function providerUpdateName(body){
  return axios({
      url: "/provider/UpdateName",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function commission(body){
  return axios({
      url: "/provider/Commission",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}

export function saveDoorPhoto(body){
  return axios({
      url: "/provider/SaveDoorPhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function saveLicencePhoto(body){
  return axios({
      url: "/provider/SaveLicencePhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function saveIDCardPhoto(body){
  return axios({
      url: "/provider/SaveIDCardPhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function providerUpdate(body){
  return axios({
      url: "/provider/Update",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function getOrderList(body){
  return axios({
      url: "/autoCollection/GetUserList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  })
}
export function batchCollection(body){
  return axios({
      url:"provider/BatchCollection",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function vehicleTypeGetList(body){
  return axios({
      url:"/vehicleType/GetList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,
      params:body
  });
}
export function OCRDriverLicense(body){
  return axios({
      url:"/scrapOrder/OCRDriverLicense",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      timeout:10000,
      withCredentials:true,            
      params: body
  })
}
export function SaveCollection(body){
  return axios({
      url:"/scrapUser/SaveCollection",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function providerSave(body){
  return axios({
      url: "/provider/save",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      timeout:15000,
      withCredentials:true,   
      data: body
  })
}
export function saveFromQRcode(body){
  return axios({
      url: "/provider/SaveFromQRcode",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      timeout:'15000',
      withCredentials:true,   
      data: body
  })
}
export function collectionSetSubmit(body){
  return axios({
      url: "/autoCollection/SetSubmit",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function SaveLicensePhoto(body){
  return axios({
      url:"/autoCollection/SaveLicensePhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function SetReceiveBankCard(body){
  return axios({
      url:"/scrapOrder/SetReceiveBankCard",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function CreateAuthCode(body){
  return axios({
      url:"/SMS/CreateAuthCode",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function getBankCardByScrapUser(body){
  return axios({
      url:"/AllinPay/GetBankCardByScrapUser",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function invalidOrder(body){
  return axios({
      url: "/autoCollection/Cancel",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function providerCompleteInfo(body){
  return axios({
      url: "/provider/CompleteInfo",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      timeout:10000,
      withCredentials:true,   
      data: body
  })
}
export function SaveProviderTag(body){
  return axios({
      url:"/tag/SaveProviderTag",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function GetAllBusinessTag(body){
  return axios({
      url:"/tag/GetPageList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function getShareOrder(body){
  return axios({
      url: "/scrapOrder/shareOrder",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  })
}
export function getOrderDetail(body){
  return axios({
      url: "/scrapOrder/get",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  })
}
export function BarcodeAdd(body){
  return axios({
      url:"BarCode/Add",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function getUserParts(body){
  return axios({
      url:"/part/GetCollectionList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function getPartOrderDetail(body){
  return axios({
      url:"/part/GetCollectionInfo",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function GetAllPart(body){
  return axios({
      url:"/part/GetAllPart",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function SavePart(body){
  return axios({
      url:"/provider/savePart",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function wasteSave(body){
  return axios({
      url:"/waste/Add",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}